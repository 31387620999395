import React, { useEffect, useState } from 'react';

const IntroAnimation = ({ onComplete }) => {
  const [animationState, setAnimationState] = useState('start');
  
  useEffect(() => {
    const timeouts = [];
    
    // Increased initial delay
    timeouts.push(setTimeout(() => {
      setAnimationState('visible');
    }, 500));

    // Extended time before expansion
    timeouts.push(setTimeout(() => {
      setAnimationState('expand');
    }, 1400));

    // Longer display time
    timeouts.push(setTimeout(() => {
      setAnimationState('exit');
    }, 2600));

    // Extended total duration
    timeouts.push(setTimeout(() => {
      onComplete();
    }, 3500));

    return () => timeouts.forEach(timeout => clearTimeout(timeout));
  }, [onComplete]);

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1b1816',
    zIndex: 9999,
    overflow: 'hidden'
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    position: 'relative'
  };

  const textStyle = {
    fontSize: '4rem',
    fontWeight: 'bold',
    color: '#937047',
    transition: 'all 1.5s ease',  // Slower text transition
    opacity: animationState === 'start' ? 0 : 
            animationState === 'exit' ? 0 : 1,
    transform: animationState === 'start' ? 'translateY(-20px)' :
              animationState === 'visible' ? 'translateY(0)' :
              animationState === 'expand' ? 'translateY(0) scale(1.1)' :
              'translateY(10px)',
    textShadow: animationState === 'expand' ? '0 0 20px rgba(147, 112, 71, 0.3)' : 'none'
  };

  const circleStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: '2px solid #937047',
    transition: 'all 1.2s ease',  // Slower circle transition
    opacity: animationState === 'start' ? 0 :
            animationState === 'exit' ? 0 : 0.6,
    transform: animationState === 'start' ? 'scale(0.75)' :
              animationState === 'visible' ? 'scale(1)' :
              animationState === 'expand' ? 'scale(1.2)' :
              'scale(1.4)'
  };

  const decorativeLines = Array(4).fill(null).map((_, index) => ({
    position: 'absolute',
    width: animationState === 'expand' ? '200px' : '0px',
    height: '1px',
    backgroundColor: '#937047',
    transition: 'all 1.5s ease',  // Slower line transition
    opacity: animationState === 'visible' || animationState === 'expand' ? 0.3 : 0,
    transform: `rotate(${index * 90}deg)`,
    top: index % 2 === 0 ? '50%' : 'auto',
    left: index % 2 === 1 ? '50%' : 'auto',
    bottom: index === 2 ? '50%' : 'auto',
    right: index === 1 ? '50%' : 'auto',
    transitionDelay: `${index * 0.2}s`  // Longer delay between lines
  }));

  const pulseCircles = Array(3).fill(null).map((_, index) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    border: '1px solid #937047',
    opacity: animationState === 'expand' ? 0 : 0.2,
    transform: `scale(${1 + (index * 0.2)})`,
    transition: 'all 1.2s ease',  // Slower pulse transition
    transitionDelay: `${index * 0.2}s`  // Longer delay between pulses
  }));

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <div style={textStyle}>
          Welcome
        </div>
        <div style={{ position: 'relative' }}>
          <div style={circleStyle}>
            {animationState === 'visible' && pulseCircles.map((style, index) => (
              <div key={index} style={style} />
            ))}
          </div>
          {decorativeLines.map((style, index) => (
            <div key={`line-${index}`} style={style} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntroAnimation;